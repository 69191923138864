@import "../free/plugins";

@import "choices";
@import "glidejs";
@import "green-audio-player";
@import "fullcalendar";
@import "fullcalendar-extend";
@import "datatable";
@import "datatable-extend";
@import "dragula";
@import "kanban";
@import "sweetalert2";
@import "sweetalert2-extend";
@import "dropzone";
@import "highlight";
@import "quill";
@import "choices";
@import "list-check";
@import "photoswipe";
@import "rating-widget";
@import "leaflet";
@import "multi-step";
