// =========================================================
// * Soft UI Design System PRO - v1.0.9
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/soft-ui-design-system-pro
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)
//
// Coded by www.creative-tim.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// Bootstrap Functions
@import "soft-design-system-pro/bootstrap/functions";

// Custom Variables
@import "soft-design-system-pro/custom/variables";

// Theme Variables
@import "soft-design-system-pro/variables";

// Bootstrap Core
@import "soft-design-system-pro/bootstrap/bootstrap";

// Theme Core
@import "soft-design-system-pro/theme-pro";

// Custom Variables
@import "soft-design-system-pro/custom/styles";
