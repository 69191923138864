section a{
    text-decoration: underline;
}

a[data-cryptex]{
    cursor: pointer;
}

blockquote{
    padding-left: 1rem;
    border-left: solid 4px #f0f0f0;
}

.ae-headline{
    border: solid 1px #ffffff;
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 300;
}


.bg-gradient-black{
    background-color: rgba(0,0,0,0.4);
}

footer{
    border-top: solid 10px #0a0a0a;
}

.material-icons {
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    fill: #000000;
  }

.icon-xs .material-icons svg{
    width: 18px;
    height: 18px;
}

.icon-sm .material-icons svg{
    width: 24px;
    height: 24px;
}

.icon-md .material-icons svg{
    width: 32px;
    height: 32px;
}

.icon-lg .material-icons svg{
    width: 48px;
    height: 48px;
}

@each $color, $value in $theme-colors {
    .icon-#{$color} svg{
        fill: $value !important;
    }
}

.navbar-toggler + .navbar-collapse .dropdown.nav-item .dropdown-menu{
    overflow: hidden;
}


@include media-breakpoint-down(md) {
    .page-header {
        h1{
            font-size: 1.5rem;
        }
        p.lead{
            font-size: 0.9rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .page-header {
        h1{
            font-size: 1.2rem;
        }
        p.lead{
            font-size: 0.8rem;
        }
    }
}